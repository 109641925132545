import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonTenants'


const listtenantsApi =(params)=>getAction(baseURL+"/list",params);

const addtenantsApi=(params)=>postAction(baseURL+"/add",params);

const edittenantsApi=(params)=>putAction(baseURL+"/edit",params);

const deletetenantsByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selecttenantsByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selecttenantsByIdApi,
    deletetenantsByIdApi,
    edittenantsApi,
    addtenantsApi,
    listtenantsApi
}
