<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
          <el-row>
            <el-form-item label="租户代码" prop="tenantCode">
              <el-input
                placeholder="请输入租户代码"
                v-model="addEditForm.tenantCode"
                clearable
                v-if="!editShow"
              >
                <template slot="prepend">{{prefixCode}}</template>
              </el-input>
              <span v-else>{{addEditForm.tenantCode}}</span>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="租户名" prop="tenantName">
              <el-input
                placeholder="请输入租户名"
                v-model="addEditForm.tenantName"
                prop="tenantName"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="租户类型" prop="tenantType" @change="tenantTypeButton">
              <el-radio-group v-model="addEditForm.tenantType" @change="tenantTypeButton">
                <el-radio label="OFFICIAL">正式</el-radio>
                <el-radio label="TEMPORARY">临时</el-radio>
                <el-radio label="INTERNAL">内部</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="绑定人" prop="adminUser">
              <el-select v-model="addEditForm.adminUser" placeholder="请选择" v-if="this.opeType==1">
                <el-option
                  v-for="item in userList"
                  :key="item.userSign"
                  :label="item.userName"
                  :value="item.userSign"
                ></el-option>
              </el-select>
              <span v-else>测试</span>
            </el-form-item>
          </el-row>
          <el-row v-if="addEditForm.tenantType=='TEMPORARY'">
            <el-form-item label="过期时间" prop="invalidTime">
              <el-date-picker
                v-model="addEditForm.invalidTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="状态" prop="status">
              <el-radio v-model="addEditForm.status" :label="1">正常</el-radio>
              <el-radio v-model="addEditForm.status" :label="2">冻结</el-radio>
            </el-form-item>
          </el-row>
        </el-form>

        <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button
            type="primary"
            @click="addFormButton()"
            :loading="loading"
          >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import moment from "moment";

import {
  selecttenantsByIdApi,
  deletetenantsByIdApi,
  edittenantsApi,
  addtenantsApi,
  listtenantsApi
} from "@/api/system/tenants/tenants.js";

import { getNoBindTenantsUserApi } from "@/api/system/user/user.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {
        status: 1,
        tenantType: "OFFICIAL",
        invalidTime: null,
        tenantCode: "",
        adminUser: ""
      },
      timer: null,
      ids: null,
      prefixCode: "",
      opeType: 1,
      userList: [],
      userSgin: ""
    };
  },
  methods: {
    show(id, type) {
      this.dialog = true;
      this.opeType = type;
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
        this.createCode();
        this.getNoBindTenantsUserButton();
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.prefixCode = "";
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      if (this.addEditForm.tenantType != "TEMPORARY") {
        this.addEditForm.invalidTime = null;
      }
      this.addEditForm.tenantCode =
        this.prefixCode + this.addEditForm.tenantCode;
      if (this.opeType === 1) {
        console.log("res", this.addEditForm);
        addtenantsApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      } else {
        edittenantsApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(id) {
      selecttenantsByIdApi(id).then(res => {
        this.addEditForm = res.result;
        this.userSgin = res.result.adminUser;
      });

      
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    createCode() {
      var a = moment(new Date()).format("YYYY-MM-DD");
      this.prefixCode = a + "_";
    },
    tenantTypeButton(val) {
      if (val !== "TEMPORARY") {
        this.addEditForm.invalidTime = null;
      }
    },
    getNoBindTenantsUserButton() {
      getNoBindTenantsUserApi().then(res => {
        this.userList = res.result;
      });
    },
    showTenants() {
    }
  }
};
</script>
<style scoped>
</style>



